import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable, interval, Subject } from 'rxjs';
import { tap, takeWhile, takeUntil } from 'rxjs/operators';

import { SharedDataService } from '../services';

@Injectable()
export class TimerInterceptor implements HttpInterceptor {
  /**
   * to keep the track of no. of requests triggered
   * @type {number}
   */
  protected requestCount = 0;
  public isBrowser: boolean = false;

  private _unSubscribedAll: Subject<void> = new Subject<void>();

  /**
   * Constructs a new instance of the TimerInterceptor class.
   *
   * @param {Router} router - The Angular Router instance.
   * @param {SharedDataService} sharedDataService - The SharedDataService instance.
   */
  constructor(
    public router: Router,
    private sharedDataService: SharedDataService,
  ) {
    this.isBrowser = this.sharedDataService.localService.isBrowser;
    sharedDataService.loadingBarSourceReset.subscribe(res => {
      if (res && this.requestCount > 0) {
        this.requestCount = 0;
        this.handleRequest();
      }
    });
  }

  /**
   * Intercepts the HTTP request and handles the response.
   *
   * @param {HttpRequest<any>} request - The HTTP request to be intercepted.
   * @param {HttpHandler} next - The next handler in the chain.
   * @return {Observable<HttpEvent<any>>} The observable that emits the HTTP event.
   */
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.isBrowser) {
      this.requestCount++;
    }
    // the following will emit every 3 seconds until all requests have returned
    interval(3000)
      .pipe(
        takeWhile((value: any) => this.isBrowser && this.requestCount > 0),
        takeUntil(this._unSubscribedAll),
      )
      .subscribe((val: any) => {
        // to make sure the snack bar is not already opened
      });

    return next.handle(request).pipe(
      tap({
        /**
         * Handles the next HTTP event.
         *
         * @param {HttpEvent<any>} event - The HTTP event to handle.
         */
        next: (event: HttpEvent<any>) => {
          if (this.isBrowser) {
            if (event instanceof HttpResponse) {
              this.requestCount--;
              // once the request count has exhausted i.e. all requests have returned then simple dismiss the rendered snack bar
              if (this.requestCount === 0) {
                this.handleRequest();
              }
            } else if (this.requestCount > 0 && !this.sharedDataService.loadingBarSource.value) {
              this.handleRequest(true);
            }
          }
        },
        /**
         * Handles the error response.
         *
         * @param {any} err - The error response.
         */
        error: (err: any) => {
          if (this.isBrowser && err instanceof HttpErrorResponse) {
            this.requestCount--;
            if (this.requestCount < 1 && this.sharedDataService.loadingBarSource.value) {
              this.handleRequest();
            }
          }
        },
    /**
     * Completes the function execution.
     *
     * @return {void} This function does not return anything.
     */
        complete: () => {
          if (
            this.isBrowser &&
            this.requestCount < 1 &&
            this.sharedDataService.loadingBarSource.value
          ) {
            this.handleRequest();
          }
        },
      }),
    );
  }

  /**
   * Handles the request by showing the loading bar and changing the form submit status.
   *
   * @param {boolean} [bool=false] - Indicates whether to show the loading bar and change the form submit status.
   */
  private handleRequest(bool: boolean = false) {
    this.sharedDataService.showLoadingBar(bool);
    this.sharedDataService.changeFormSubmitStatus(bool);
  }
}
