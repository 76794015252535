import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';

import { bootstrapApplication } from '@angular/platform-browser';

import { appConfig } from './app/app.config';

if (environment.production) {
  enableProdMode();
}
// document.addEventListener('DOMContentLoaded', () => {
//   bootstrapApplication(AppComponent, {
//     providers: [
//       {
//         provide: HTTP_INTERCEPTORS,
//         useClass: BaseUrlInterceptor,
//         multi: true,
//       },
//       {
//         provide: HTTP_INTERCEPTORS,
//         useClass: JwtInterceptor,
//         multi: true,
//       },
//       {
//         provide: HTTP_INTERCEPTORS,
//         useClass: TimerInterceptor,
//         multi: true,
//       },
//       LocalStorageService,
//       BaseNetworkService,
//       HttpService,
//       AuthService,
//       TranslationLoaderService,
//       importProvidersFrom(
//         BrowserModule,
//         AppRoutingModule,
//         LayoutModule,
//         HttpClientModule,
//         ...ROOT_LEVEL_MODULES,
//       ),
//       AuthGuard,
//       {
//         provide: HIGHLIGHT_OPTIONS,
//         useValue: {
//           coreLibraryLoader: () => import('highlight.js/lib/core'),
//           languages: {
//             xml: () => import('highlight.js/lib/languages/xml'),
//             typescript: () => import('highlight.js/lib/languages/typescript'),
//             scss: () => import('highlight.js/lib/languages/scss'),
//           },
//         },
//       },
//       provideClientHydration(),
//       provideAnimations(),
//     ],
//   }).catch(err => console.error(err));
// });

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
